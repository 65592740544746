import { useModal } from "react-modal-hook";

import Button from "@components/Button";
import ConfirmAedReplenishedWizardModal from "@modals/ConfirmAedReplenishedWizardModal";
import { useSidePanels } from "@util/SidePanels";
import { EscalationProps } from "@util/viewModel";

const AedPartsReplacementFooter = ({
  escalation,
}: EscalationProps<Api.UnmonitoredPartsReplacement>) => {
  const { pop } = useSidePanels();

  const [showConfirmReplenishedModal, closeConfirmReplenishedModal] = useModal(
    () => (
      <ConfirmAedReplenishedWizardModal
        unitReminderId={escalation.UnitReminderId}
        controllerSerialNumber={escalation.ControllerSerialNumber}
        shipmentId={escalation.ShipmentId}
        onClose={() => {
          pop();
          closeConfirmReplenishedModal;
        }}
      />
    ),
    [escalation, pop],
  );

  return (
    <Button buttonType="action" onClick={showConfirmReplenishedModal}>
      Complete replacement
    </Button>
  );
};

export default AedPartsReplacementFooter;
