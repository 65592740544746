import { useModal } from "react-modal-hook";

import Button from "@components/Button";
import ConfirmReplacementWizard from "@modals/ConfirmReplacementWizard";
import { useSidePanels } from "@util/SidePanels";
import { EscalationProps } from "@util/viewModel";

const AedPartsReplacementConfirmationFooter = ({
  escalation,
}: EscalationProps<Api.UnmonitoredExpiredPartsReplacementConfirmation>) => {
  const { pop } = useSidePanels();

  const [showConfirmReplenishmentModal, closeConfirmReplenishmentModal] =
    useModal(
      () => (
        <ConfirmReplacementWizard
          unitReminderId={escalation.UnitReminderId}
          controllerSerialNumber={escalation.ControllerSerialNumber}
          onClose={(completed) => {
            completed && pop();
            closeConfirmReplenishmentModal();
          }}
        />
      ),
      [escalation.ControllerSerialNumber, escalation.UnitReminderId, pop],
    );

  return (
    <>
      <Button
        buttonType="action"
        onClick={() => {
          showConfirmReplenishmentModal();
        }}
      >
        Resolve this action…
      </Button>
    </>
  );
};

export default AedPartsReplacementConfirmationFooter;
