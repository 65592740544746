import { useCallback } from "react";
import { useTheme } from "styled-components";

import { IIcon } from "@icons";
import { Palette, Palettes, PaletteHelperFns, ThemeIcons } from "@themes/types";

const attachHelperFns = <T extends Pick<Palette, "foreground" | "background">>(
  palette: T,
): T & PaletteHelperFns => ({
  ...palette,
  toCss: () => ({
    color: palette.foreground,
    background: palette.background,
  }),
  toCssInverted: () => ({
    color: palette.background,
    background: palette.foreground,
  }),
});

export const useThemeHelper = () => {
  const { palettes, icons } = useTheme();

  return {
    icon: useCallback(
      (icon: keyof ThemeIcons["other"]): IIcon => icons.other[icon],
      [icons.other],
    ),

    unitTypeIcon: useCallback(
      (type?: Api.UnitType) =>
        type && icons.unitType[type] ? icons.unitType[type] : "question-circle",
      [icons.unitType],
    ),

    eventCategoryIcon: useCallback(
      (category?: Api.EventCategory): IIcon =>
        category && icons.eventCategory[category]
          ? icons.eventCategory[category]
          : "question-circle",
      [icons.eventCategory],
    ),

    messageTypeIcon: useCallback((type?: keyof Palettes["messages"]): IIcon => {
      switch (type) {
        default:
        case "success":
          return "check-alt-circle";
        case "info":
          return "exclamation-alt-circle";
        case "notice":
          return "exclamation-triangle";
        case "error":
          return "exclamation-alt-circle";
      }
    }, []),

    statesPalette: useCallback(
      (state?: keyof Palettes["states"]) =>
        attachHelperFns(
          state && Object.keys(palettes.states).includes(state)
            ? palettes.states[state]
            : palettes.states.neutral,
        ),
      [palettes.states],
    ),

    messagesPalette: useCallback(
      (level?: keyof Palettes["messages"]) =>
        attachHelperFns(
          level && Object.keys(palettes.messages).includes(level)
            ? palettes.messages[level]
            : palettes.messages.info,
        ),
      [palettes.messages],
    ),

    rolePalette: useCallback(
      (role?: Api.RoleName) =>
        attachHelperFns(
          role && Object.keys(palettes.roles).includes(role)
            ? palettes.roles[role]
            : palettes.roles.Owner,
        ),
      [palettes.roles],
    ),

    shipmentStatusPalette: useCallback(
      (status?: Api.ShipmentStatus) => {
        let result;

        switch (status) {
          // Notice - All states before shipment has been sent, or where there is
          // nothing the customer can do.
          case "Created":
          case "LabelPending":
          case "LabelCreated":
          case "Printed": // StarShipIt
          case "AwaitingCollection": // StarShipIt
          case "AwaitingDispatch": // Toll
          case "Sent":
          case "Dispatched": // StarShipIt
          case "InTransit": // StarShipIt
          case "OutForDelivery": // StarShipIt
            result = palettes.states.neutral;
            break;

          // Warning - All states where the customer needs to do something
          case "PickupInStore": // StarShipIt
          case "AttemptedDelivery": // StarShipIt
          case "Unpacking":
          case "Delivered": // StarShipIt
            result = palettes.states.warning;
            break;

          // Positive - All sucessfully completed shipments
          case "Complete":
            result = palettes.states.good;
            break;

          // Danger - All states where action is urgently needed or shipment cancelled
          case "OverdueUnpacking":
          case "NeverCompleted":
          case "Cancelled":
          case "LabelCreationError":
          case "Exception": // StarShipIt
            result = palettes.states.bad;
            break;

          // Neutral - Unknown states
          default:
            result = palettes.states.na;
        }

        return attachHelperFns(result);
      },
      [palettes],
    ),

    stockReplenishmentStatusPalette: useCallback(
      (replenishment?: Api.UnitReplenishmentStatus) => {
        let result;

        switch (replenishment) {
          case "Warehouse Packing ":
          case "Required ":
            result = palettes.states.warning;
            break;

          case "Required (Critical)":
          case "Signed-Off, Pack-List Sent (Critical)":
          case "Signed-Off (Critical)":
          case "Warehouse Packing (Critical)":
            result = palettes.states.bad;
            break;

          case "":
            result = palettes.states.good;
            break;

          default:
            result = palettes.states.neutral;
        }

        return attachHelperFns(result);
      },
      [
        palettes.states.bad,
        palettes.states.good,
        palettes.states.neutral,
        palettes.states.warning,
      ],
    ),

    severityPalette: useCallback(
      (severity?: Api.EventSeverityLevel) => {
        let result;

        switch (severity) {
          case "High":
            result = palettes.severity.high;
            break;
          case "Medium":
            result = palettes.severity.medium;
            break;
          case "Low":
            result = palettes.severity.low;
            break;
          default:
            result = palettes.states.na;
        }

        return attachHelperFns(result);
      },
      [
        palettes.severity.high,
        palettes.severity.low,
        palettes.severity.medium,
        palettes.states.na,
      ],
    ),

    readinessPalette: useCallback(
      (value: number) =>
        attachHelperFns(
          value === -1 || isNaN(value)
            ? palettes.states.na
            : value < 25
              ? palettes.states.bad
              : value < 75
                ? palettes.states.warning
                : palettes.states.good,
        ),
      [
        palettes.states.bad,
        palettes.states.good,
        palettes.states.na,
        palettes.states.warning,
      ],
    ),

    daysGonePalette: useCallback(
      (value: number | null) =>
        attachHelperFns(
          value === null
            ? palettes.states.na
            : value > 30
              ? palettes.states.good
              : palettes.states.bad,
        ),
      [palettes.states.bad, palettes.states.good, palettes.states.na],
    ),

    escalationsPalette: useCallback(
      (escalations: Api.Escalation | Api.Escalation[]) => {
        const es = escalations instanceof Array ? escalations : [escalations];

        return attachHelperFns(
          es.some((e) => e.CompletedEscalation3)
            ? palettes.states.bad
            : es.some((e) => e.CompletedEscalation2)
              ? palettes.states.warning
              : palettes.states.neutral,
        );
      },
      [palettes.states.bad, palettes.states.neutral, palettes.states.warning],
    ),

    /**
     * Color palette to use for the given number of escalations.
     */
    escalationsCountPalette: useCallback(
      (count: number) =>
        attachHelperFns(
          count === 0 ? palettes.states.good : palettes.states.bad,
        ),
      [palettes.states.bad, palettes.states.good],
    ),
  };
};
