import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import {
  Control,
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { IconWell, MessageText } from "@design/helpers";
import DS from "@design/system";
import { IIcon } from "@icons";
import { useThemeHelper } from "@util/useThemeHelper";

import {
  LabelledOptionGroup,
  LabelledOptionGroupPlaceholder,
  LabelledSwitch,
} from "../FormControls";
import GroupHeader from "../GroupHeader";
import Icon from "../Icon";

interface AlertDescriptor {
  name: Api.AlertName;
  label?: string;
  tip?: string;
  type?: "onoff" | "multi";
}

interface AlertGroup {
  title: string;
  description?: string;
  alerts: AlertDescriptor[];
}

const getAlertDescription = (alert: Api.UserAlert) => {
  if (!alert.IsEnabled) return "No alert";

  switch (alert.AlertMethod) {
    case "Email":
      return "Email";
    case "PushNotification":
      return "Mobile app notification";
    case "SMS":
      return "SMS";
    case "EmailAndPushNotification":
      return "App notification and email";
  }
};

const getAlertValue = (alert: Api.UserAlert) => {
  if (!alert.IsEnabled) return ["none"];

  switch (alert.AlertMethod) {
    case "Email":
      return ["email"];
    case "PushNotification":
      return ["push"];
    case "SMS":
      return ["sms"];
    case "EmailAndPushNotification":
      return ["email", "push"];
  }
};

const getAlertMethod = (values: string[]): Api.AlertMethod => {
  if (values[values.length - 1] === "sms") return "SMS";

  if (values.includes("email") && values.includes("push"))
    return "EmailAndPushNotification";

  if (values.includes("email")) return "Email";
  if (values.includes("push")) return "PushNotification";

  return "Email";
};

type NotificationOptions = {
  icon: IIcon;
  label: string;
  value: string;
}[];

const AlertController = ({
  alert,
  control,
}: {
  alert: AlertDescriptor;
  control: Control<Api.User, object>;
}) => {
  const { features } = useTheme();

  const { fields } = useFieldArray({
    control,
    name: "Alerts",
  });

  const index = useMemo(
    () => fields.findIndex((field) => field.AlertName === alert.name),
    [alert, fields],
  );

  const NOTIFICATION_OPTIONS: NotificationOptions = features.smsNotifications
    ? [
        { icon: "times", label: "No alerts", value: "none" },
        { icon: "envelope", label: "Email", value: "email" },
        {
          icon: "mobile",
          label: "Mobile app notifications",
          value: "push",
        },
        { icon: "comment-dots", label: "SMS", value: "sms" },
      ]
    : [
        { icon: "times", label: "No alerts", value: "none" },
        { icon: "envelope", label: "Email", value: "email" },
        {
          icon: "mobile",
          label: "Mobile app notifications",
          value: "push",
        },
      ];
  if (index === -1) return null;

  return (
    <Controller
      control={control}
      name={`Alerts.${index}`}
      render={({ field }) =>
        alert.type === "onoff" ? (
          <LabelledSwitch
            name={`Alerts[${index}].AlertMethod`}
            title={alert.tip}
            label={alert.label ?? alert.name}
            labelOn="Yes"
            labelOff="No"
            checked={field.value.IsEnabled}
            onBlur={field.onBlur}
            onChange={(e) =>
              field.onChange({
                ...field.value,
                IsEnabled: e.target.checked,
                AlertMethod: "Email",
              })
            }
            ref={field.ref}
          />
        ) : (
          <LabelledOptionGroup
            name={`Alerts[${index}].AlertMethod`}
            label={alert.label ?? alert.name}
            description={getAlertDescription(field.value)}
            title={alert.tip}
            options={NOTIFICATION_OPTIONS}
            value={getAlertValue(field.value)}
            onChange={(value: string[]) =>
              field.onChange({
                ...field.value,
                IsEnabled:
                  value.length === 0 || value.includes("none") ? false : true,
                AlertMethod:
                  value.length === 0 || value.includes("none")
                    ? field.value.AlertMethod
                    : getAlertMethod(value),
              })
            }
          />
        )
      }
    />
  );
};

const NotificationsTab = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { messagesPalette } = useThemeHelper();

  const { control, setValue } = useFormContext<Api.User>();

  const [roles, alerts] = useWatch({
    control,
    name: ["Roles", "Alerts"],
  });

  const { fields } = useFieldArray({
    control,
    name: "Alerts",
  });

  const alertGroups: AlertGroup[] = useMemo(
    () => [
      {
        title: `First Aid ${t("term.incident_other")}`,
        description: `First Aid ${t(
          "term.incident_one",
        )} alerts are sent as a collection of events that represent a single first aid ${t(
          "term.incident_one",
        ).toLowerCase()}.`,
        alerts: [
          {
            name: "High Severity Incident",
            label: `${t("severity.high")} ${t("term.incident_one")}`,
          },
          {
            name: "Medium Severity Incident",
            label: `${t("severity.medium")} ${t("term.incident_one")}`,
          },
          {
            name: "Low Severity Incident",
            label: `${t("severity.low")} ${t("term.incident_one")}`,
          },
        ],
      },
      {
        title: "Escalations",
        description:
          "Escalations are triggered when certain events, that may require your attention, occur. These notifications will be delivered via email.",
        alerts: [
          {
            name: "Escalation: Non-Consumable",
            label: "Tools not returned to cabinet",
            tip: "If a tool is missing for too long",
            type: "onoff",
          },
          {
            name: "Escalation: Expired Stock",
            label: "Expired stock not swapped out",
            tip: "When new products have been delivered to replace expired stock, but not yet place into the cabinet.",
            type: "onoff",
          },
          {
            name: "Escalation: Replenishment",
            label: "New supplies not put in cabinet",
            tip: "When new products have been delivered, but not yet place into the cabinet.",
            type: "onoff",
          },
          {
            name: "Escalation: Station-Off",
            label: "The cabinet is off",
            tip: "When the cabinet has remained off for now justifable reason.",
            type: "onoff",
          },
        ],
      },
    ],
    [t],
  );

  useEffect(() => {
    if (!roles || !roles[0]) return;

    const defaultAlerts =
      queryClient.getQueryData<Api.UserAlert[]>([
        "defaultUserAlerts",
        roles[0].Name,
      ]) ?? [];

    // TODO: Update these if the role changes and if they weren't manually changed prior.
    if (!alerts || alerts.length === 0) {
      setValue("Alerts", defaultAlerts);
    }
  }, [roles, queryClient, alerts, setValue]);

  return (
    <div style={{ display: "grid", gap: DS.margins.regular }}>
      {alertGroups.map((group) => (
        <div
          key={group.title}
          style={{
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <div>
            <GroupHeader>{group.title}</GroupHeader>
            {group.description && (
              <IconWell style={messagesPalette("info").toCss()}>
                <Icon name="exclamation-alt-circle" />
                <MessageText>{group.description}</MessageText>
              </IconWell>
            )}
          </div>
          {!fields || !fields.length ? (
            <>
              <LabelledOptionGroupPlaceholder optionCount={4} width={312} />
              <LabelledOptionGroupPlaceholder optionCount={4} width={312} />
              <LabelledOptionGroupPlaceholder optionCount={4} width={312} />
            </>
          ) : (
            group.alerts.map((alert, i) => (
              <AlertController
                key={`alert-${i}`}
                alert={alert}
                control={control}
              />
            ))
          )}
        </div>
      ))}
    </div>
  );
};

export default NotificationsTab;
