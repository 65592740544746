import { useTheme } from "styled-components";

import GroupHeader from "@components/GroupHeader";
import Icon from "@components/Icon";
import MediaCard, { MediaCardPlaceholder } from "@components/MediaCard";
import { Aed } from "@components/WizardIcons";
import { IconWell, SmallText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useUnitFull, useUnitShipmentSummary } from "@state/hooks";
import { EscalationProps } from "@util/viewModel";

const AedPartsReplacement = ({
  escalation,
}: EscalationProps<Api.UnmonitoredPartsReplacement>) => {
  const { palettes } = useTheme();

  const { data: unit } = useUnitFull(escalation.ControllerSerialNumber);

  const { data: lines, isLoading: isLoadingShipmentSummary } =
    useUnitShipmentSummary(
      escalation.ControllerSerialNumber,
      escalation.ShipmentId,
    );

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <IconWell>
        <Icon
          name="exclamation-alt-circle"
          color={palettes.messages.notice.background}
        />
        <SmallText>
          Replacement items for this AED have arrived. Please replace the
          expired items with these new ones and confirm this has been done.
        </SmallText>
      </IconWell>

      {!unit ? (
        <MediaCardPlaceholder showDescriptionExt />
      ) : (
        <MediaCard
          image={<Aed />}
          title={unit.UnitName}
          description={unit.Description}
          descriptionExt={unit.Model}
        />
      )}

      <div>
        <GroupHeader>Delivered Items</GroupHeader>

        <UnstyledList>
          {isLoadingShipmentSummary ? (
            <>
              <MediaCardPlaceholder />
              <MediaCardPlaceholder />
            </>
          ) : (
            lines.map(({ line, old }) => (
              <li key={line.ShipmentLineID}>
                <MediaCard
                  title={line.Name}
                  description={
                    old ? (
                      <span>
                        To replace <strong>{old.ProductName}</strong> ({old.SKU}
                        )
                      </span>
                    ) : (
                      <span>
                        New <strong>{line.Name}.</strong>
                      </span>
                    )
                  }
                  image={
                    <div
                      style={{
                        width: 48,
                        height: 48,

                        backgroundColor: "white",
                        backgroundImage: `url(${line.ImageUrl})`,
                        backgroundSize: 40,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",

                        border: `solid 1px ${palettes.body.border}`,
                        borderRadius: DS.radii.item,
                      }}
                    />
                  }
                />
              </li>
            ))
          )}
        </UnstyledList>
      </div>
    </div>
  );
};

export default AedPartsReplacement;
