import c from "color";
import { MouseEventHandler, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { TinyText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useEscalations, useGroup, useShipments } from "@state/hooks";
import { TextLoader } from "@util/ContentLoader";
import {
  EscalationTitle,
  getEscalationDescription,
  getEscalationIcon,
  getEscalationTitle,
  openAndNotPausedEscalationsOnly,
} from "@util/escalations";
import { activeShipmentsOnly } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import { IIcon } from "../icons";
import { Bar, BarSize } from "./Bar";
import Carousel from "./Carousel";
import Donut, { DonutPlaceholder, DonutValue } from "./Donut";
import Icon from "./Icon";
import IconButton from "./IconButton";
import ListItem from "./ListItem";
import ShipmentCard from "./ShipmentCard";

const Container = styled.div`
  height: 128px;

  color: ${({ theme }) => theme.palettes.body.foreground};

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  border: solid 1px ${({ theme }) => theme.palettes.body.border};
  border-radius: ${DS.radii.largeItem};
`;

const Label = styled.h3`
  margin: 0;
  padding: ${DS.margins.regularCss("trl")};

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.palettes.body.small};

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
`;

export const MetricsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: ${DS.margins.regular};
`;

export const NumberMetric = ({
  label,
  value,
  title,
  selected,
  isLoading,
  isError,
  onChange,
}: {
  label: string;
  value: React.ReactNode;
  title?: string;
  selected?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  onChange?: React.MouseEventHandler;
}) => {
  const theme = useTheme();

  return (
    <Container
      style={{
        flexBasis: 128,
        maxWidth: 220,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Label>
        {label}
        {title && (
          <IconButton
            active={selected}
            title={title}
            buttonType="transparent"
            fit="tight"
            icon="filter"
            iconSize={14}
            onClick={onChange}
          />
        )}
      </Label>
      <div
        style={{
          padding: DS.margins.microCss("rl"),
          fontSize: 50,
          fontWeight: 700,
          lineHeight: "50px",

          display: "grid",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <TextLoader width={50} />
        ) : isError ? (
          <Icon
            name="exclamation-alt-circle"
            color={theme.palettes.states.bad.background}
            size={24}
          />
        ) : (
          value ?? <span style={{ color: theme.palettes.body.small }}>--</span>
        )}
      </div>
    </Container>
  );
};

export type BarVerticalColumn<T extends string> = {
  id: T;
  title: string;
  count: number | number[];
  color?: string;
  icon?: IIcon;
  onChange?: React.ChangeEventHandler;
};

export const BarVerticalMetric = <T extends string>({
  label,
  value,
  data,
  size,
  color,
  selected,
  isLoading,
  isError,
  onChange,
  hideEmpty = false,
}: {
  label: string;
  value: string | number;
  data: BarVerticalColumn<T>[];
  size?: BarSize;
  color?: string | string[];
  selected?: T[] | null;
  isLoading?: boolean;
  isError?: boolean;
  onChange?: (id: T) => void;
  hideEmpty?: boolean;
}) => {
  const { palettes } = useTheme();

  if (!color) color = palettes.body.accent;

  const max = useMemo(() => {
    return data.reduce((p, d) => {
      return Math.max(
        p,
        d.count instanceof Array ? d.count.reduce((k, c) => k + c, 0) : d.count,
      );
    }, 0);
  }, [data]);

  const nonEmptyEscalationCategories = useMemo(() => {
    const columns = hideEmpty
      ? data.filter(
          (d) => (d.count instanceof Array && d.count.length) || d.count,
        )
      : data;
    return columns.filter((col) => !hideEmpty || col.count);
  }, [data, hideEmpty]);

  return (
    <Container
      style={{
        flexBasis: 230,
        flexShrink: 0,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Label>{label}</Label>
      {isError ? (
        <div
          style={{
            display: "grid",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            name="exclamation-alt-circle"
            color={palettes.states.bad.background}
            size={24}
          />
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: DS.margins.micro,
          }}
        >
          <div
            style={{
              padding: DS.margins.regularCss("tl"),
              fontSize: 28,
              fontWeight: 700,
            }}
          >
            {value}
          </div>
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              paddingRight: 16,
              display: "grid",
              gridAutoFlow: "column",
              gap: 0,
            }}
          >
            {nonEmptyEscalationCategories.map((col) => (
              <Bar
                key={col.id}
                title={col.title}
                icon={isLoading ? undefined : col.icon}
                size={size}
                value={isLoading ? Math.round(Math.random() * 10) : col.count}
                color={
                  isLoading
                    ? c(palettes.body.foreground)
                        .mix(c(palettes.body.background), 0.8)
                        .string()
                    : col.color ?? color
                }
                max={isLoading ? 10 : max}
                showLabel={false}
                onChange={onChange ? () => onChange(col.id) : undefined}
                selected={(selected && selected.includes(col.id)) ?? false}
                hasSelection={(selected && selected.length > 0) ?? false}
              />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

const ICON_SIZE = 20;

const TokenContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: ${DS.margins.nano};

  font-size: 14px;
  line-height: 1;
  text-align: start;

  border: 0;
  border-radius: ${DS.radii.item};

  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 4px;
  align-items: center;

  transition: all 200ms;
`;

const Token = ({
  color,
  value,
  label,
}: {
  color: string;
  value: number;
  label: string;
  onClick?: MouseEventHandler;
}) => {
  const theme = useTheme();

  return (
    <TokenContainer>
      <div
        style={{
          width: 12,
          height: 12,
          background: color,
          borderRadius: DS.radii.item,
        }}
      />
      <div style={{ color: theme.palettes.body.small }}>{value}</div>
      <div
        style={{
          whiteSpace: "nowrap",
          fontWeight: 600,
          color: theme.palettes.body.small,
        }}
      >
        {label}
      </div>
    </TokenContainer>
  );
};

const SelectableTokenContainer = styled.label<{
  selected: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: ${DS.margins.nano};

  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  text-align: start;
  color: ${({ theme, selected }) =>
    selected
      ? theme.palettes.buttons.neutralSelected.foreground
      : theme.palettes.body.foreground};

  background: ${({ theme, selected }) =>
    selected
      ? theme.palettes.buttons.neutralSelected.background
      : "transparent"};
  border: 0;
  border-radius: ${DS.radii.item};

  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 4px;
  align-items: center;

  transition: all 200ms;

  &:hover {
    background: ${({ theme, selected }) =>
      selected
        ? theme.palettes.buttons.neutralSelected.dim
        : theme.palettes.body.dim};
  }
`;

const SelectableToken = ({
  color,
  value,
  label,
  selected,
  onChange,
}: {
  color: string;
  value: number;
  label: string;
  selected?: boolean;
  onChange?: React.ChangeEventHandler;
}) => {
  const theme = useTheme();

  return (
    <SelectableTokenContainer selected={!!selected}>
      <input
        style={{
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
        }}
        type="checkbox"
        name={label}
        onChange={onChange}
        checked={selected}
      />
      <div
        style={{
          width: 10,
          height: 10,
          background: color,
          borderRadius: DS.radii.item,
          border: "solid 1px",
          borderColor: selected ? theme.palettes.body.background : color,
        }}
      />
      <div
        style={{
          color: selected
            ? theme.palettes.buttons.neutralSelected.foreground
            : theme.palettes.body.small,
        }}
      >
        {value}
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          fontWeight: 600,
        }}
      >
        {label}
      </div>
    </SelectableTokenContainer>
  );
};

export const DonutMetric = <T extends string>({
  tKey,
  label,
  caption,
  icon,
  half,
  data,
  isLoading,
  isError,
  showLegend,
  selected,
  onChange,
}: {
  tKey?: Util.AllowedPaths<I18n.TranslationBase, I18n.Metrics.Donut>;
  label?: string;
  caption?: string;
  icon?: IIcon;
  half?: boolean;
  data: DonutValue<T>[];
  isLoading?: boolean;
  isError?: boolean;
  showLegend?: boolean;
  selected?: T[] | null | string;
  onChange?: (id: T) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container
      style={{
        flexBasis: 128,
        flexShrink: 0,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Label>{label ?? (tKey && t(`${tKey}.title`)) ?? "--"}</Label>
      <div
        style={{
          padding: DS.margins.regularCss("rl"),
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: isError
            ? "1fr"
            : showLegend
              ? "auto auto"
              : "1fr",
          gap: DS.margins.micro,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: half ? 4 : 0,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: 8,
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <DonutPlaceholder
              half={half}
              radius={half ? 40 - 12 : 32 - 10}
              thickness={half ? 12 : 10}
            />
          ) : isError ? (
            <Icon
              name="exclamation-alt-circle"
              color={theme.palettes.states.bad.background}
              size={24}
            />
          ) : (
            <Donut
              half={half}
              radius={half ? 40 - 12 : 32 - 10}
              thickness={half ? 12 : 10}
              values={data}
              selected={selected}
              onChange={onChange}
            />
          )}
          {icon && !isError && (
            <div
              style={{
                position: "absolute",
                top: half ? 40 - ICON_SIZE / 2 : 32 - ICON_SIZE / 2,
              }}
            >
              <Icon
                name={icon}
                size={ICON_SIZE}
                color={theme.palettes.body.small}
              />
            </div>
          )}
          {caption && !isError && (
            <TinyText>
              {isLoading ? <TextLoader width={80} /> : caption}
            </TinyText>
          )}
        </div>

        {showLegend && !isError && (
          <UnstyledList gap={0}>
            {data.map((d) => (
              <li key={d.id}>
                {onChange ? (
                  <SelectableToken
                    color={d.color}
                    value={d.count}
                    label={d.title ?? "--"}
                    selected={
                      selected instanceof Array
                        ? selected.includes(d.id)
                        : selected === d.id
                    }
                    onChange={() => onChange(d.id)}
                  />
                ) : (
                  <Token
                    color={d.color}
                    value={d.count}
                    label={d.title ?? "--"}
                  />
                )}
              </li>
            ))}
          </UnstyledList>
        )}
      </div>
    </Container>
  );
};

export const EscalationsVerticalBarMetric = ({
  storeId,
  groupId,
  allStores,
}: {
  storeId?: string;
  groupId?: string;
  allStores?: boolean;
}) => {
  const history = useHistory();
  const { escalationsPalette } = useThemeHelper();

  const { data: group } = useGroup();
  const { data: allEscalations } = useEscalations({
    storeId,
    groupId,
    allStores,
  });

  const handleEscalationChange = useCallback(
    (escalation: Api.UnitReminderType) => {
      history.location.pathname.includes("group")
        ? history.push(
            `/group/${group?.GroupId}/devices?action-types=${escalation}`,
          )
        : history.location.pathname.includes("all")
          ? history.push(`/all-stores/devices?action-types=${escalation}`)
          : history.push(
              `/store/${storeId}/devices?action-types=${escalation}`,
            );
    },

    [group?.GroupId, history, storeId],
  );

  const escalations = useMemo(() => {
    const openEscalations =
      allEscalations?.filter(openAndNotPausedEscalationsOnly) ?? [];

    const values = {
      aed: openEscalations.filter(
        (escalation) =>
          escalation.ReminderType === "DeviceInspection" ||
          escalation.ReminderType === "UnmonitoredPartsReplacement" ||
          escalation.ReminderType ===
            "UnmonitoredExpiredPartsReplacementConfirmation",
      ),
      powerOff: openEscalations.filter(
        (escalation) => escalation.ReminderType === "PowerOff",
      ),
      missingTool: openEscalations.filter(
        (escalation) =>
          escalation.ReminderType === "MissingTool" ||
          escalation.ReminderType === "PutMultiUseItemBackIntoKit",
      ),
      replenishment: openEscalations.filter(
        (escalation) => escalation.ReminderType === "Replenishment",
      ),
      expiredStock: openEscalations.filter(
        (escalation) => escalation.ReminderType === "ExpiredStock",
      ),
    };

    return {
      ...values,
      count: openEscalations.length,
      max: Math.max(
        ...Object.keys(values).map(
          (v) => values[v as keyof typeof values].length,
        ),
      ),
    };
  }, [allEscalations]);

  const escalationData = [
    escalations.aed,
    escalations.powerOff,
    escalations.missingTool,
    escalations.replenishment,
    escalations.expiredStock,
  ]
    .filter((group) => group.length)
    .map((escalationGroup) => ({
      id: escalationGroup[0].ReminderType,
      title: `${getEscalationTitle(escalationGroup[0].ReminderType)}: ${
        escalationGroup.length
      }`,
      icon: getEscalationIcon(escalationGroup[0].ReminderType),
      count: escalationGroup.length,
      color: escalationsPalette(escalationGroup).background,
    }));

  return (
    <BarVerticalMetric
      label="Actions to do"
      value={escalations.count}
      size="heavy"
      hideEmpty={true}
      onChange={(escalationTypes) => handleEscalationChange(escalationTypes)}
      data={[...escalationData] as BarVerticalColumn<Api.UnitReminderType>[]}
    />
  );
};

export const EscalationsMetric = ({
  label,
  storeId,
}: {
  label: string;
  storeId?: string;
}) => {
  const { escalationsPalette } = useThemeHelper();

  const { data: escalations } = useEscalations({ storeId });

  return (
    <Container
      style={{
        flexBasis: 230,
        flexShrink: 0,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Label>{label}</Label>
      <div
        style={{
          padding: DS.margins.regular,
        }}
      >
        <Carousel
          autoRotate
          compact
          infinite
          dots="default"
          items={escalations?.filter(openAndNotPausedEscalationsOnly) ?? []}
        >
          {(escalation) => (
            <ListItem
              key={escalation.UnitReminderId}
              image={
                <Icon
                  name={getEscalationIcon(escalation.ReminderType)}
                  color={escalationsPalette(escalation).background}
                />
              }
              title={<EscalationTitle escalation={escalation} />}
              description={getEscalationDescription(escalation.ReminderType)}
            />
          )}
        </Carousel>
      </div>
    </Container>
  );
};

export const ShipmentsMetric = ({
  label,
  storeId,
  onChange,
  title,
  selected,
}: {
  label: string;
  storeId?: string;
  onChange?: React.MouseEventHandler;
  title?: string;
  selected?: boolean;
}) => {
  const { palettes } = useTheme();

  const shipments = useShipments({ storeId, numberDays: 90 });

  const activeShipments = useMemo(
    () => shipments?.data?.filter(activeShipmentsOnly),
    [shipments.data],
  );

  return (
    <Container
      style={{
        flexBasis: 128,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Label>
        {label}
        {title && (
          <IconButton
            active={selected}
            title={title}
            buttonType="transparent"
            fit="tight"
            icon={"filter"}
            iconSize={14}
            onClick={onChange}
          />
        )}
      </Label>
      <div
        style={{
          padding: DS.margins.regular,
          display: "grid",
          gap: DS.margins.regular,
          gridTemplateColumns:
            activeShipments && activeShipments.length > 0 ? "auto 1fr" : "1fr",
        }}
      >
        {!activeShipments ||
          (activeShipments.length === 0 && (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                justifyItems: "center",
                color: palettes.body.small,
                fontWeight: 600,
              }}
            >
              No active shipments
            </div>
          ))}
        {activeShipments && activeShipments.length > 0 && (
          <div
            style={{
              fontSize: 28,
              fontWeight: 700,
            }}
          >
            {activeShipments?.length ?? 0}
          </div>
        )}
        {activeShipments && activeShipments.length > 0 && (
          <div style={{ padding: 0, alignSelf: "center" }}>
            <ShipmentCard shipments={[activeShipments[0]]} size="progress" />
          </div>
        )}
      </div>
    </Container>
  );
};
